//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-800:_9532,_3908,_9685,_1240,_4416,_2712,_7080,_6612;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-800')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-800', "_9532,_3908,_9685,_1240,_4416,_2712,_7080,_6612");
        }
      }catch (ex) {
        console.error(ex);
      }